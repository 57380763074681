import { useContext } from 'react'
import { Metric } from '../../../components'
import { TenantTokenContext } from '../../../contexts'
import { hyphenizeDate } from '../../../utils'

const AdminDashboard = () => {
  const { tenantToken } = useContext(TenantTokenContext)
  const today = hyphenizeDate(new Date())
  const queryParams = { from: today, to: today }

  return (
    <div className='flex flex-1 flex-wrap gap-6'>
      <Metric
        title='Ventas de hoy' path={`/octopus_os/t/${tenantToken}/metrics/sales`} format='currency'
        queryParams={queryParams}
      />
      <Metric
        title='Ticket promedio' path={`/octopus_os/t/${tenantToken}/metrics/average_ticket`}
        format='currency' queryParams={queryParams}
      />
      <Metric
        title='No. de pedidos' path={`/octopus_os/t/${tenantToken}/metrics/orders_count`}
        queryParams={queryParams}
      />
    </div>
  )
}

export default AdminDashboard
