import { useFormContext } from 'react-hook-form'
import { methodToDomId } from '../../utils'
import { ErrorMessage } from '@hookform/error-message'
import { forwardRef } from 'react'

const CheckboxField = forwardRef(({
  label, method, defaultValue, serverError, disabled = false, fieldClass = '', labelClass = '', ...props
}, parentRef) => {
  const id = methodToDomId(method)
  const { register, formState: { errors } } = useFormContext()
  const { onChange, onBlur, name, ref: inputRef, value } = register(method, { value: defaultValue, disabled })

  const combineRefs = (element) => {
    if (parentRef) { parentRef.current = element }

    inputRef(element)
  }

  return (
    <div className={`field ${fieldClass}`}>
      <div className='control'>
        <input
          id={id}
          ref={combineRefs}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          type='checkbox'
          value={value}
          disabled={disabled}
          {...props}
        />
        {label && <label htmlFor={id} className={`label  ml-2 ${labelClass}`}><b>{label}</b></label>}
      </div>
      <ErrorMessage
        errors={errors} name={name}
        render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>}
      />
      {(serverError) && <p className='text-red-500 text-sm'>{serverError}</p>}
    </div>
  )
})

export default CheckboxField
