import { useContext } from 'react'
import { translations } from '../../../locales'
import { LocaleContext, SnackbarContext, TenantTokenContext, TokenContext } from '../../../contexts'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Header, LabelValue, Tag } from '../../../components'
import { useFetch } from '../../../hooks'
import { buildApiUrl, toCurrency } from '../../../utils'
import OrdersTable from './OrdersTable'
import { adminRoute } from '../../../routesHelpers'

const AdminCashClosing = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { id } = useParams()
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const { setSnackbar } = useContext(SnackbarContext)
  const navigate = useNavigate()
  const { data } = useFetch(
    buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings/${id}`), null, [updatedAt]
  )
  const record = data.data

  const [searchParams] = useSearchParams()
  const formattedSearchParams = Object.fromEntries(searchParams)

  const customerInvoicedUrl = buildApiUrl(
    `/octopus_os/t/${tenantToken}/admin/cash_closings/${id}/orders?type=customer_invoiced`,
    { locale, customer_invoiced_page: formattedSearchParams.customer_invoiced_page || 1 }
  )

  const invoiceableUrl = buildApiUrl(
    `/octopus_os/t/${tenantToken}/admin/cash_closings/${id}/orders?type=invoiceable`,
    { locale, invoiceable_page: formattedSearchParams.invoiceable_page || 1 }
  )

  const confirmCashClosing = async () => {
    if (!window.confirm('Usted confirmará este corte de caja. ¿Desea continuar?')) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings/${id}/confirmations`),
      { method: 'POST', headers }
    )

    if (response.ok) {
      navigate(
        adminRoute('cashClosing', { id }), { state: { adminCashClosing: { updatedAt: Date.now() } } }
      )
    }
  }

  const generateGlobalInvoice = async () => {
    const confirmationMessage =
      `Se generará una factura global por la cantidad de ${toCurrency(record.invoiceable_total)}. ¿Desea continuar?`

    if (!window.confirm(confirmationMessage)) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings/${id}/invoices`),
      { method: 'POST', headers }
    )
    const data = await response.json()

    if (response.ok) {
      navigate(
        adminRoute('cashClosing', { id }), { state: { adminCashClosing: { updatedAt: Date.now() } } }
      )
    } else {
      setSnackbar({ message: (Object.values(data.errors) || []).join('. '), type: 'alert' })
    }
  }

  if (!record) return <p>Cargando...</p>

  return (
    <>
      <Header title={translations[locale].models.cashClosing} subtitle={record.date}>
        <Button type='button' onClick={confirmCashClosing} disabled={record.status === 'done'}>
          {translations[locale].helpers.confirm.text}
        </Button>

        <Button type='button' onClick={generateGlobalInvoice} disabled={record.status !== 'done' || record.is_invoiced}>
          {translations[locale].helpers.generate.text} {translations[locale].models.invoice}
        </Button>
      </Header>

      {
        record.status === 'done'
          ? <Tag>{record.status}</Tag>
          : <Tag textColor='black' backgroundColor='bg-slate-200'>{record.status}</Tag>
      }

      <div className='flex justify-between gap-3 mt-6'>
        <LabelValue
          label={translations[locale].attributes.cashClosing.ordersCount}
          value={record.orders_count}
        />
        <LabelValue
          label={translations[locale].attributes.cashClosing.total}
          value={(toCurrency(record.total))}
        />
        <LabelValue
          label={translations[locale].attributes.cashClosing.customerInvoicedTotal}
          value={(toCurrency(record.customer_invoiced_total))}
        />
        <LabelValue
          label={translations[locale].attributes.cashClosing.invoiceableTotal}
          value={(toCurrency(record.invoiceable_total))}
        />
      </div>

      <div className='mt-12'>
        <OrdersTable
          title='Pedidos facturados a clientes' url={customerInvoicedUrl}
          pageParam='customer_invoiced_page' updatedAt={updatedAt}
        />
      </div>

      <div className='mt-12'>
        <OrdersTable
          title='Pedidos sin facturar' url={invoiceableUrl}
          pageParam='invoiceable_page' updatedAt={updatedAt}
        />
      </div>
    </>
  )
}

export default AdminCashClosing
