import { useContext, useState } from 'react'
import { Form, TextField, TextAreaField, TertiaryLink, Button } from '../../../components'
import { translations } from '../../../locales'
import { LocaleContext } from '../../../contexts'

const AdminVendorForm = ({ url, method, record = {}, onSuccess = () => {} }) => {
  const { locale } = useContext(LocaleContext)
  const [errors, setErrors] = useState({})

  return (
    <Form
      url={url}
      method={method}
      onSuccessfulSubmit={onSuccess}
      onFailedSubmit={(errors) => setErrors(errors)}
    >
      <TextField
        method='octopus_os_vendor.name'
        placeholder={translations[locale].attributes.vendor.name}
        label={translations[locale].attributes.vendor.name}
        fieldClass='mb-4' serverError={errors.name}
        defaultValue={record?.name}
      />

      <TextField
        method='octopus_os_vendor.rfc'
        placeholder={translations[locale].attributes.vendor.rfc}
        label={translations[locale].attributes.vendor.rfc}
        fieldClass='mb-4' serverError={errors.rfc}
        defaultValue={record?.rfc}
      />

      <TextField
        method='octopus_os_vendor.phone'
        placeholder={translations[locale].attributes.vendor.phone}
        label={translations[locale].attributes.vendor.phone}
        fieldClass='mb-4' serverError={errors.phone}
        defaultValue={record?.phone} type='phone' min={10} max={13}
      />

      <TextField
        method='octopus_os_vendor.email'
        placeholder={translations[locale].attributes.vendor.email}
        label={translations[locale].attributes.vendor.email}
        fieldClass='mb-4' serverError={errors.email}
        defaultValue={record?.email} type='email'
      />

      <TextField
        method='octopus_os_vendor.street'
        placeholder={translations[locale].attributes.vendor.street}
        label={translations[locale].attributes.vendor.street}
        fieldClass='mb-4' serverError={errors.street}
        defaultValue={record?.street}
      />

      <TextField
        method='octopus_os_vendor.street_number'
        placeholder={translations[locale].attributes.vendor.streetNumber}
        label={translations[locale].attributes.vendor.streetNumber}
        fieldClass='mb-4' serverError={errors.streetNumber}
        defaultValue={record?.street_number}
      />

      <TextAreaField
        method='octopus_os_vendor.additional_info'
        placeholder={translations[locale].attributes.vendor.additionalInfo}
        label={translations[locale].attributes.vendor.additionalInfo}
        fieldClass='mb-4' serverError={errors.additionalInfo}
        defaultValue={record?.additional_info}
      />

      <TextField
        method='octopus_os_vendor.neighborhood'
        placeholder={translations[locale].attributes.vendor.neighborhood}
        label={translations[locale].attributes.vendor.neighborhood}
        fieldClass='mb-4' serverError={errors.neighborhood}
        defaultValue={record?.neighborhood}
      />

      <TextField
        method='octopus_os_vendor.zip_code'
        placeholder={translations[locale].attributes.vendor.zipCode}
        label={translations[locale].attributes.vendor.zipCode}
        fieldClass='mb-4' serverError={errors.zipCode}
        defaultValue={record?.zip_code} min={5} max={5}
      />

      <TextField
        method='octopus_os_vendor.city'
        placeholder={translations[locale].attributes.vendor.city}
        label={translations[locale].attributes.vendor.city}
        fieldClass='mb-4' serverError={errors.city}
        defaultValue={record?.city}
      />

      <TextField
        method='octopus_os_vendor.state'
        placeholder={translations[locale].attributes.vendor.state}
        label={translations[locale].attributes.vendor.state}
        fieldClass='mb-4' serverError={errors.state}
        defaultValue={record?.state}
      />

      <TextField
        method='octopus_os_vendor.country'
        placeholder={translations[locale].attributes.vendor.country}
        label={translations[locale].attributes.vendor.country}
        fieldClass='mb-4' serverError={errors.country}
        defaultValue='México' disabled
      />

      <div className='flex justify-end gap-x-2'>
        <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
        <Button type='submit'>{translations[locale].helpers.save.text}</Button>
      </div>
    </Form>
  )
}

export default AdminVendorForm
