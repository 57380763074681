export const UserTagIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='icon'>
      <path
        fill={color} d='M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-46 48C80 304 0 384
        0 482c0 17 13 30 30 30h388c10 0 19-5 25-12L343 400a79 79 0 0 1-23-56v-33c-16-5-33-7-50-7h-92zm206-80c-18
        0-32 14-32 32v83c0 17 7 33 19 45l107 107c19 19 49 19 68 0l73-73c19-19 19-49 0-68L512 243a64
         64 0 0 0-45-19h-83zm24 80a24 24 0 1 1 48 0 24 24 0 1 1-48 0z'
      />
    </svg>
  )
}
