import { useContext } from 'react'
import { LocaleContext, TenantTokenContext, TokenContext } from '../../../contexts'
import { translations } from '../../../locales'
import {
  Dropdown, Header, PrimaryLink, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  DropdownItem, Tag,
  BooleanIcon
} from '../../../components'
import { useFetch } from '../../../hooks'
import { buildApiUrl, toCurrency } from '../../../utils'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { adminRoute } from '../../../routesHelpers'

const AdminCashClosings = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings`, { locale, ...Object.entries(searchParams) })
  const { data } = useFetch(url, null, [updatedAt])
  const records = data.data

  const removeCashClosing = async (id) => {
    if (!window.confirm('¿Desea eliminar este corte de caja?')) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/cash_closings/${id}`),
      { method: 'DELETE', headers }
    )

    if (response.ok) {
      navigate(
        adminRoute('cashClosings'), { state: { adminCashClosings: { updatedAt: Date.now() } } }
      )
    }
  }

  if (!records) return <p>Cargando...</p>

  return (
    <>
      <Header title={translations[locale].cashClosings.title}>
        <PrimaryLink href={adminRoute('newCashClosing')} modal>
          {translations[locale].helpers.new.male.text}
        </PrimaryLink>
      </Header>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Fecha</b></TableCell>
              <TableCell><b>Total</b></TableCell>
              <TableCell><b>No. de pedidos</b></TableCell>
              <TableCell><b>Corte por</b></TableCell>
              <TableCell><b>Estado</b></TableCell>
              <TableCell><b>Facturado</b></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {records.map(record => {
              return (
                <TableRow
                  key={record.id}
                  className='hover:bg-slate-100 hover:cursor-pointer'
                  onDoubleClick={() => { navigate(adminRoute('cashClosing', { id: record.id })) }}
                >
                  <TableCell>{record.date}</TableCell>
                  <TableCell>{toCurrency(record.total || 0)}</TableCell>
                  <TableCell>{record.orders_count}</TableCell>
                  <TableCell>{record.executed_by?.name}</TableCell>
                  <TableCell>
                    <Tag
                      textColor={record.status === 'draft' ? 'black' : 'text-green-600'}
                      backgroundColor={record.status === 'draft' ? 'bg-slate-200' : 'bg-green-100'}
                      text={record.status}
                    />
                  </TableCell>
                  <TableCell><BooleanIcon value={record.is_invoiced} /></TableCell>
                  <TableCell className='has-text-right is-narrow'>
                    <Dropdown>
                      <DropdownItem
                        name={`${translations[locale].helpers.delete.text}`}
                        textColor='text-red-500'
                        handleClick={() => removeCashClosing(record.id)}
                        disabled={record.status === 'done' || record.is_invoiced}
                      />
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AdminCashClosings
