import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { useFetch } from '../../../hooks/useFetch'
import { Header, TableContainer, TableBody, TableHead, TableRow, TableCell, Table, TableFoot } from '../../../components'
import { translations } from '../../../locales'
import { buildApiUrl, toCurrency } from '../../../utils'

const AdminOrder = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { orderToken } = useParams()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale })
  const { data } = useFetch(url)
  const record = data.data

  if (!record) {
    return (<div>Loading...</div>)
  }

  return (
    <>
      <Header title={`${translations[locale].models.order} #${record.display_number}`} />

      <div className='my-4'>
        <p>{record.customer.legal_name}</p>
        <p>{record.customer.rfc}</p>
        <p>{record.customer.phone}</p>
        <p>{record.customer.email}</p>
      </div>

      <div className='my-4'>
        <p>{record.status}</p>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='font-bold is-narrow'>Cantidad</TableCell>
              <TableCell className='font-bold'>Nombre</TableCell>
              <TableCell className='font-bold text-right is-narrow'>Precio unitario</TableCell>
              <TableCell className='font-bold text-right is-narrow'>Descuento</TableCell>
              <TableCell className='font-bold text-right is-narrow'>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {record.order_items.map(orderItem => {
              return (
                <TableRow key={orderItem.id}>
                  <TableCell className='align-middle'>{orderItem.amount}</TableCell>
                  <TableCell className='align-middle'>{orderItem.product_name} ({orderItem.sales_presentation_name})</TableCell>
                  <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.unit_price)}</TableCell>
                  <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.unit_discount)}</TableCell>
                  <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.total)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFoot>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell>{toCurrency(record.total)}</TableCell>
            </TableRow>
          </TableFoot>
        </Table>
      </TableContainer>

    </>
  )
}

export default AdminOrder
