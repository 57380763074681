const ReceiptIcon = ({ color = 'currentColor', className = 'icon' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' className={className}>
      <path
        fill={color} d='M14 2c9-4 19-2 26 4l40 34 40-34c9-8 23-8 32 0l40 34 40-34c9-8 23-8 32
        0l40 34 40-34a24 24 0 0 1 40 18v464a24 24 0 0 1-40 18l-40-34-40 34c-9 8-23 8-32 0l-40-34-40
        34c-9 8-23 8-32 0l-40-34-40 34a24 24 0 0 1-40-18V24C0 15 6 6 14 2zm82 142c-9 0-16 7-16 16s7
        16 16 16h192c9 0 16-7 16-16s-7-16-16-16H96zM80 352c0 9 7 16 16 16h192c9 0 16-7 16-16s-7-16-16-16H96c-9
        0-16 7-16 16zm16-112c-9 0-16 7-16 16s7 16 16 16h192c9 0 16-7 16-16s-7-16-16-16H96z'
      />
    </svg>
  )
}

export { ReceiptIcon }
