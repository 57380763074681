import { Route, Routes, useLocation } from 'react-router-dom'
import { AdminLayout, ModalLayout } from './layouts'
import { TokenContext, LocaleContext, TenantTokenContext, SnackbarContext } from './contexts'
import PrivateRoute from './PrivateRoute'
import { useToken, useLocale, useTenantToken } from './hooks'
import { route, adminRoute } from './routesHelpers'
import {
  SignIn, AdminDashboard, AdminProducts, AdminOrders, AdminProduct,
  AdminNewProduct, AdminNewSalesPresentation, AdminEditSalesPresentation,
  AdminEditProductEquivalence, AdminEditProduct, AdminCashier, AdminCustomers,
  AdminNewCustomer, AdminEditCustomer, AdminEditCashierCustomerInfo, AdminNewCashierOrderItem,
  AdminEditOrderItem,
  AdminCashierOrderPayment, AdminCashierOrderCompleted,
  AdminOrder, AdminEditDiscountCashierOrderItem,
  AdminInvoices,
  AdminInvoiceCancelation,
  AdminEditOrderCustomer,
  AdminCashClosings,
  AdminNewCashClosing,
  AdminCashClosing,
  AdminCashierOrders,
  AdminMetrics,
  AdminSalesPresentation,
  AdminVendors,
  AdminNewVendor,
  AdminEditVendor
} from './pages'

import './index.scss'
import { useState } from 'react'

const App = () => {
  const { token, setToken } = useToken()
  const { locale, setLocale } = useLocale()
  const { tenantToken, setTenantToken } = useTenantToken()
  const [snackbar, setSnackbar] = useState({})
  const location = useLocation()
  const previousLocation = location.state?.previousLocation

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <TenantTokenContext.Provider value={{ tenantToken, setTenantToken }}>
          <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
            <Routes location={previousLocation || location}>
              <Route path={route('root')} element={<SignIn />} />

              <Route element={<AdminLayout />}>
                <Route path={adminRoute('dashboard')} element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
                <Route
                  path={adminRoute('products')}
                  element={<PrivateRoute><AdminProducts updatedAt={location.state?.adminProducts?.updatedAt} /></PrivateRoute>}
                />
                <Route path={adminRoute('showProduct')} element={<PrivateRoute><AdminProduct /></PrivateRoute>} />
                <Route path={adminRoute('orders')} element={<PrivateRoute><AdminOrders updatedAt={location.state?.adminOrders?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('cashier')} element={<PrivateRoute><AdminCashier /></PrivateRoute>} />
                <Route path={adminRoute('customers')} element={<PrivateRoute><AdminCustomers updatedAt={location.state?.adminCustomers?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('customers')} element={<PrivateRoute><AdminCustomers updatedAt={location.state?.adminCustomers?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('cashierOrderCompleted')} element={<PrivateRoute><AdminCashierOrderCompleted /></PrivateRoute>} />
                <Route path={adminRoute('invoices')} element={<PrivateRoute><AdminInvoices updatedAt={location.state?.adminInvoices?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('cashClosings')} element={<PrivateRoute><AdminCashClosings updatedAt={location.state?.adminCashClosings?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('cashClosing')} element={<PrivateRoute><AdminCashClosing updatedAt={location.state?.adminCashClosing?.updatedAt} /></PrivateRoute>} />
                <Route path={adminRoute('metrics')} element={<PrivateRoute><AdminMetrics /></PrivateRoute>} />
                <Route path={adminRoute('salesPresentation')} element={<PrivateRoute><AdminSalesPresentation /></PrivateRoute>} />
                <Route path={adminRoute('vendors')} element={<PrivateRoute><AdminVendors updatedAt={location.state?.adminVendors?.updatedAt} /></PrivateRoute>} />
              </Route>

              <Route path='*' element={<p>This is my error page for 404</p>} />
            </Routes>

            {
              previousLocation && (
                <Routes>
                  <Route element={<ModalLayout />}>
                    <Route path={adminRoute('products')} element={<PrivateRoute><AdminProducts /></PrivateRoute>} />
                    <Route path={adminRoute('showProduct')} element={<PrivateRoute><AdminProduct /></PrivateRoute>} />
                    <Route path={adminRoute('orders')} element={<PrivateRoute><AdminOrders /></PrivateRoute>} />
                    <Route path={adminRoute('showOrder')} element={<PrivateRoute><AdminOrder /></PrivateRoute>} />
                    <Route path={adminRoute('newProduct')} element={<PrivateRoute><AdminNewProduct /></PrivateRoute>} />
                    <Route path={adminRoute('editProduct')} element={<PrivateRoute><AdminEditProduct /></PrivateRoute>} />
                    <Route path={adminRoute('newSalesPresentation')} element={<PrivateRoute><AdminNewSalesPresentation /></PrivateRoute>} />
                    <Route path={adminRoute('editSalesPresentation')} element={<PrivateRoute><AdminEditSalesPresentation /></PrivateRoute>} />
                    <Route path={adminRoute('editProductEquivalence')} element={<PrivateRoute><AdminEditProductEquivalence /></PrivateRoute>} />
                    <Route path={adminRoute('newCustomer')} element={<PrivateRoute><AdminNewCustomer /></PrivateRoute>} />
                    <Route path={adminRoute('editCustomer')} element={<PrivateRoute><AdminEditCustomer /></PrivateRoute>} />
                    <Route path={adminRoute('editCashierCustomerInfo')} element={<PrivateRoute><AdminEditCashierCustomerInfo /></PrivateRoute>} />
                    <Route path={adminRoute('newCashierOrderItem')} element={<PrivateRoute><AdminNewCashierOrderItem /></PrivateRoute>} />
                    <Route path={adminRoute('editCashierOrderItem')} element={<PrivateRoute><AdminEditOrderItem /></PrivateRoute>} />
                    <Route path={adminRoute('cashierOrderPayment')} element={<PrivateRoute><AdminCashierOrderPayment /></PrivateRoute>} />
                    <Route path={adminRoute('editDiscountCashierOrderItem')} element={<PrivateRoute><AdminEditDiscountCashierOrderItem /></PrivateRoute>} />
                    <Route path={adminRoute('invoiceCancelation')} element={<PrivateRoute><AdminInvoiceCancelation /></PrivateRoute>} />
                    <Route path={adminRoute('editOrderCustomer')} element={<PrivateRoute><AdminEditOrderCustomer /></PrivateRoute>} />
                    <Route path={adminRoute('newCashClosing')} element={<PrivateRoute><AdminNewCashClosing /></PrivateRoute>} />
                    <Route path={adminRoute('cashierOrders')} element={<PrivateRoute><AdminCashierOrders /></PrivateRoute>} />
                    <Route path={adminRoute('newVendor')} element={<PrivateRoute><AdminNewVendor /></PrivateRoute>} />
                    <Route path={adminRoute('editVendor')} element={<PrivateRoute><AdminEditVendor /></PrivateRoute>} />
                  </Route>

                  <Route path='*' element={<p>This is my error page for 404</p>} />
                </Routes>
              )
            }
          </SnackbarContext.Provider>
        </TenantTokenContext.Provider>
      </LocaleContext.Provider>
    </TokenContext.Provider>
  )
}

export default App
