import { useContext, useState } from 'react'
import {
  TertiaryLink, Button, SelectField, TextField, Form, CheckboxField
} from '../../../../../components'
import { translations } from '../../../../../locales'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { useParams } from 'react-router-dom'
import { apiBaseUrl } from '../../../../../routesHelpers'

const SalesPresentationForm = ({ url, method, salesPresentation = {}, onSuccess = () => {} }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()
  const [errors, setErrors] = useState({})

  return (
    <Form
      method={method}
      url={url}
      onSuccessfulSubmit={(data) => { onSuccess(data) }}
      onFailedSubmit={(errors) => { setErrors(errors) }}
    >
      <TextField type='hidden' method='locale' defaultValue={locale} />

      <TextField
        method='octopus_os_product_sales_presentation.name'
        rules={{ required: false }}
        placeholder={translations[locale].attributes.salesPresentation.name}
        label={translations[locale].attributes.salesPresentation.name}
        fieldClass='mb-4' serverError={errors.name}
        defaultValue={salesPresentation?.name}
      />

      <TextField
        method='octopus_os_product_sales_presentation.sku'
        rules={{ required: false }}
        placeholder={translations[locale].attributes.salesPresentation.sku}
        label={translations[locale].attributes.salesPresentation.sku}
        fieldClass='mb-4' serverError={errors.sku}
        defaultValue={salesPresentation?.sku}
      />

      <div className='flex justify-between mb-4 gap-x-2'>
        <TextField
          method='octopus_os_product_sales_presentation.base_unit_price'
          valdates={{ required: true }}
          placeholder={0} type='number'
          label={translations[locale].attributes.salesPresentation.baseUnitPrice}
          fieldClass='flex-1' serverError={errors.base_unit_price}
          defaultValue={salesPresentation?.base_unit_price}
          step={0.01}
        />

        <SelectField
          id='salesPresentationCurrency' method='octopus_os_product_sales_presentation.currency'
          defaultOptions={[{ label: 'MXN', value: 'MXN' }, { label: 'USD', value: 'USD' }]}
          label={translations[locale].attributes.salesPresentation.currency}
          fieldClass='flex-1' serverError={errors.currency}
          defaultValue={salesPresentation?.currency_for_select || { label: 'MXN', value: 'MXN' }}
        />
      </div>

      <CheckboxField
        method='octopus_os_product_sales_presentation.taxes_included'
        label={translations[locale].attributes.salesPresentation.taxesIncluded}
        fieldClass='mb-6' serverError={errors.taxes_included}
        defaultValue={salesPresentation?.taxes_included !== undefined ? salesPresentation?.taxes_included : true}
      />

      <div className='flex justify-between mb-4 gap-x-2'>
        <TextField
          method='octopus_os_product_sales_presentation.quantity_value'
          rules={{ required: false }}
          placeholder={0} type='number'
          label={translations[locale].attributes.salesPresentation.quantityValue}
          fieldClass='flex-1' serverError={errors.quantity_value}
          defaultValue={salesPresentation?.quantity_value}
        />

        <SelectField
          method='octopus_os_product_sales_presentation.measure_unit'
          label={translations[locale].attributes.salesPresentation.unit}
          fieldClass='flex-1'
          fetchOptionsUrl={`${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations/units?sales_presentation_id=${salesPresentation?.id}`}
          serverError={errors.unit}
          defaultValue={salesPresentation?.unit_for_select}
        />
      </div>

      <div className='flex justify-end'>
        <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>

        <Button type='submit'>{translations[locale].helpers.save.text}</Button>
      </div>
    </Form>
  )
}

export default SalesPresentationForm
