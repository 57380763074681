import { useContext } from 'react'
import { Button, DateField, Form, Header, Metric } from '../../../components'
import { translations } from '../../../locales'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { hyphenizeDate } from '../../../utils'
import { useSearchParams } from 'react-router-dom'

const AdminMetrics = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams)
  const from = queryParams.from || hyphenizeDate(new Date())
  const to = queryParams.to || hyphenizeDate(new Date())

  return (
    <>
      <Header title={translations[locale].metrics.title} />

      <Form
        method='GET'
        updateSearchParamsOnly
        onSuccessfulSubmit={() => {}}
      >
        <div className='flex items-center gap-4'>
          <div className='flex items-center'>
            <DateField method='from' defaultValue={from} />
            <p className='px-3'>-</p>
            <DateField method='to' defaultValue={to} />
          </div>

          <Button type='submit'>{translations[locale].helpers.search.text}</Button>
        </div>
      </Form>

      <div className='flex flex-1 flex-wrap gap-6 mt-6'>
        <Metric
          title='Ventas' path={`/octopus_os/t/${tenantToken}/metrics/sales`}
          queryParams={queryParams} format='currency'
        />
        <Metric
          title='Ticket promedio' path={`/octopus_os/t/${tenantToken}/metrics/average_ticket`}
          queryParams={queryParams} format='currency'
        />
        <Metric
          title='No. de pedidos' path={`/octopus_os/t/${tenantToken}/metrics/orders_count`}
          queryParams={queryParams}
        />
      </div>
    </>
  )
}

export default AdminMetrics
