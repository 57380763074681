import { useContext } from 'react'
import { LocaleContext, TenantTokenContext, TokenContext } from '../../../../../contexts.js'
import { useParams, useNavigate } from 'react-router-dom'
import { translations } from '../../../../../locales/index.js'
import {
  Header, Button,
  TertiaryLink
} from '../../../../../components/index.js'
import { adminRoute, apiBaseUrl } from '../../../../../routesHelpers.js'
import { EditIcon } from '../../../../../icons/index.js'
import { useFetch } from '../../../../../hooks/useFetch.js'
import { buildApiUrl } from '../../../../../utils.js'

const AdminProductEquivalence = ({ updatedAt }) => {
  const { productId } = useParams()
  const { token } = useContext(TokenContext)
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)

  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}/equivalences`, { locale })
  const { data } = useFetch(url, {}, [updatedAt])
  const equivalence = data.data

  const enableEquivalence = async () => {
    const options = {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    }
    const response = await fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}/equivalences`,
      options
    )

    if (response.ok) {
      navigate(
        adminRoute('showProduct', { productId }),
        { state: { productEquivalence: { updatedAt: Date.now() } } }
      )
    } else {
      // implemente and snackbar or alert (maybe con Portal)
    }
  }

  return (
    <>
      <Header title={translations[locale].productEquivalences?.title}>
        <TertiaryLink href={adminRoute('editProductEquivalence', { productId })} modal>
          <EditIcon />
        </TertiaryLink>
      </Header>

      {
        equivalence
          ? <p>{equivalence.from_value} {equivalence.from_unit} = {equivalence.to_value} {equivalence.to_unit}</p>
          : <div className='flex justify-center'>
            <Button onClick={enableEquivalence}>
              {translations[locale].helpers.enable.text} {translations[locale].models.productEquivalence.toLowerCase()}
            </Button>
            </div>
      }
    </>
  )
}

export default AdminProductEquivalence
