import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../../../../components'
import AdminOrderItem from './OrderItem'

const AdminOrderItems = ({ order }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className='font-bold is-narrow'>Cantidad</TableCell>
            <TableCell />
            <TableCell className='font-bold'>Nombre</TableCell>
            <TableCell className='font-bold text-right is-narrow'>Precio unitario</TableCell>
            <TableCell className='font-bold text-right is-narrow'>Descuento</TableCell>
            <TableCell className='font-bold text-right is-narrow'>Total</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {(order.order_items || []).map(orderItem => {
            return <AdminOrderItem key={orderItem.id} orderItem={orderItem} orderToken={order.token} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdminOrderItems
