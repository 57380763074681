export const EyeIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' className='icon'>
      <path
        fill={color} d='M288 32c-81 0-145 37-193 81-46 43-78 95-92 131-4 8-4 16 0 24 14 36 46
        88 92 131 48 44 112 81 193 81s146-37 193-81c46-43 78-95 93-131 3-8 3-16 0-24-15-36-47-88-93-131-47-44-112-81-193-81zM144
        256a144 144 0 1 1 288 0 144 144 0 1 1-288 0zm144-64a64 64 0 0 1-84 61c-6-2-12 1-12 7l3
        21a96 96 0 1 0 97-121c-6 0-9 6-7 12s3 13 3 20z'
      />
    </svg>
  )
}
