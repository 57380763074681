import { useContext, useEffect, useState } from 'react'
import { TokenContext } from '../contexts'

export const useFetch = (url, initialValue, dependencies = []) => {
  const { token } = useContext(TokenContext)
  const [data, setData] = useState({ data: initialValue })

  useEffect(() => {
    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    fetch(url, { method: 'GET', headers })
      .then(response => response.json())
      .then(parsedResponse => { setData(parsedResponse) })
      .catch(() => {})
  }, [url, token, ...dependencies])

  return { data, setData }
}
