import { useContext } from 'react'
import { Header } from '../../../components'
import { translations } from '../../../locales'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import AdminVendorForm from './VendorForm'
import { buildApiUrl } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { useReferrer } from '../../../hooks'
import { adminRoute } from '../../../routesHelpers'

const AdminNewVendor = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { referrer } = useReferrer()
  const navigate = useNavigate()

  const redirectToVendors = () => {
    navigate(referrer || adminRoute('vendors'), { state: { adminVendors: { updatedAt: Date.now() } } })
  }

  return (
    <>
      <Header
        title={`${translations[locale].helpers.new.male.text} ${translations[locale].models.vendor.toLowerCase()}`}
      />

      <AdminVendorForm
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/admin/vendors`)}
        method='POST'
        onSuccess={redirectToVendors}
      />
    </>
  )
}

export default AdminNewVendor
