import { useContext } from 'react'
import { useFetch } from '../../../../../hooks'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { useParams, useSearchParams } from 'react-router-dom'
import { buildApiUrl, hyphenizeDate, toCurrency } from '../../../../../utils'
import { translations } from '../../../../../locales'
import { Button, Chart, DateField, Form, Header } from '../../../../../components'

const AdminSalesPresentationHistoricalPrices = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId, salesPresentationId } = useParams()
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams)
  const today = new Date()
  const beginningOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const from = queryParams.from || hyphenizeDate(beginningOfMonth)
  const to = queryParams.to || hyphenizeDate(today)

  const { data } = useFetch(
    buildApiUrl(
      `/octopus_os/t/${tenantToken}/admin/products/${productId}/sales_presentations/${salesPresentationId}/historical_prices`,
      queryParams
    )
  )
  const historicalPrices = data.data

  const labels = Object.keys(historicalPrices || {})
  const values = Object.values(historicalPrices || {})

  if (!historicalPrices) return <p>Cargando...</p>

  return (
    <>
      <Header title={`${translations[locale].salesPresentations.historicalPrices.title}`} />

      <Form
        method='GET'
        updateSearchParamsOnly
        onSuccessfulSubmit={() => {}}
      >
        <div className='flex items-center gap-4 mb-4'>
          <div className='flex items-center'>
            <DateField method='from' defaultValue={from} />
            <p className='px-3'>-</p>
            <DateField method='to' defaultValue={to} />
          </div>

          <Button type='submit'>{translations[locale].helpers.search.text}</Button>
        </div>
      </Form>

      <div className='bg-white p-8 rounded-3xl'>
        <Chart
          labels={labels}
          datasets={[{ label: 'Precio', data: values }]}
          options={{ scales: { y: { ticks: { callback: (value, index, ticks) => toCurrency(value) } } } }}
        />
      </div>
    </>
  )
}

export default AdminSalesPresentationHistoricalPrices
