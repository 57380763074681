const EditAltIcon = ({ color = 'currentColor', className = 'icon' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className={className}>
      <path
        fill={color} d='m363 19-49 49 130 130 49-49c25-25 25-65 0-90l-40-40a64 64 0 0 0-90 0zm-71
        71L59 324a89 89 0 0 0-23 37L1 481a24 24 0 0 0 30 30l120-35c14-4 27-12 37-22l234-234L292 90z'
      />
    </svg>
  )
}

export { EditAltIcon }
