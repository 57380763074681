export const FloppyDiskIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' className='icon'>
      <path
        fill={color} d='M64 32C29 32 0 61 0 96v320c0 35 29 64 64 64h320c35 0 64-29 64-64V173c0-17-7-33-19-45l-77-77a64
        64 0 0 0-45-19H64zm0 96c0-18 14-32 32-32h192c18 0 32 14 32 32v64c0 18-14 32-32 32H96c-18
        0-32-14-32-32v-64zm160 160a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'
      />
    </svg>
  )
}
