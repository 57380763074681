import {
  Header, TertiaryLink, Button, Form, SearchBar, RadioGroup
} from '../../../../../components'
import { translations } from '../../../../../locales'
import { useContext, useState } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRoute } from '../../../../../routesHelpers'
import { buildApiUrl, toCurrency } from '../../../../../utils'

const AdminNewCashierOrderItem = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { orderToken } = useParams()
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const [salesPresentations, setSalesPresentations] = useState()

  const onSuccessfulSearch = (data) => {
    setSalesPresentations(
      data.map(record => {
        const label = (
          <div className='flex justify-between flex-1'>
            <p>{`${record.product_name} - ${record.name}`}</p>
            <p>{toCurrency(record.unit_price)}</p>
          </div>
        )
        return { label, value: record.id }
      })
    )
  }

  const onFailedSearch = () => {
    setSalesPresentations([])
    setErrors('Occurrio un error')
  }

  const handleSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'), { state: { order: data }, replace: true })
  }

  return (
    <div>
      <Header
        title={`${translations[locale].helpers.new.male.text} ${translations[locale].models.orderItem.toLowerCase()}`}
      />

      <SearchBar
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/pos/sales_presentations`)}
        onSuccessfulSubmit={onSuccessfulSearch}
        onFailedSubmit={onFailedSearch}
        updateSearchParamsOnly={false}
        method='q.product_name_or_name_i_cont'
      />

      <Form
        method='POST'
        url={buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items`, { locale })}
        onSuccessfulSubmit={handleSuccessfulSubmit}
        onFailedSubmit={setErrors}
      >
        {
        (salesPresentations && salesPresentations.length === 0)
          ? <div>
            No se encontraron resultados
            </div>
          : <>
            {
              (salesPresentations && salesPresentations.length > 0) &&
                <>
                  <div className='mt-5'>
                    <RadioGroup
                      method='octopus_os_product_sales_presentation_id'
                      options={salesPresentations}
                    />
                  </div>

                  {
                    errors &&
                    Object.values(errors).map(error => {
                      return <p className='text-red-500 text-sm'>{error}</p>
                    })
                  }
                </>
            }
          </>
        }

        <div className='flex justify-end gap-x-2 mt-4'>
          <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
          <Button type='submit'>{translations[locale].helpers.save.text}</Button>
        </div>
      </Form>
    </div>
  )
}

export default AdminNewCashierOrderItem
