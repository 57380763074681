import { useLocation, useParams } from 'react-router-dom'
import { buildApiUrl, toCurrency } from '../../../utils'
import {
  SecondaryLink, Button, Table, TableBody, TableCell, TableFoot, TableHead, TableRow
} from '../../../components'
import { adminRoute } from '../../../routesHelpers'
import { CheckCircleIcon } from '../../../icons'
import { useContext, useRef } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { useFetch } from '../../../hooks/useFetch'
import { useReactToPrint } from 'react-to-print'

const AdminCashierOrderCompleted = () => {
  const location = useLocation()
  const { locale } = useContext(LocaleContext)
  const { orderToken } = useParams()
  const { tenantToken } = useContext(TenantTokenContext)
  const ticketRef = useRef(null)

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale })
  const { data } = useFetch(url)
  const record = data.data

  const handlePrint = useReactToPrint({
    content: () => ticketRef?.current
  })

  return (
    <>
      <div className='flex flex-1 bg-white rounded-3xl p-4 shadow-smooth items-center justify-center flex-col'>
        <div className='text-center mb-12'>
          <CheckCircleIcon color='green' className='w-24 md:w-44 m-auto' />
          <p className='text-2xl mt-4'><b>¡Pedido completado!</b></p>
        </div>

        <div className='md:flex md:justify-evenly md:gap-4 w-full'>
          <div className='text-center mb-4 md:mb-0'>
            <p className='text-5xl'>{toCurrency(location.state?.amountReceived)}</p>
            <p className='text-xl text-gray-500'>Cliente pagó con</p>
          </div>

          <div className='text-center'>
            <p className='text-5xl'>{toCurrency(location.state?.amountReceived - location.state?.total)}</p>
            <p className='text-xl text-gray-500'>Cambio</p>
          </div>
        </div>

        <div className='flex mt-8 gap-12'>
          <SecondaryLink href={adminRoute('cashier')}>
            Regresar a caja
          </SecondaryLink>

          <Button onClick={handlePrint} backgroundColor='bg-transparent' textColor='text-blue-600'>
            Imprimir ticket
          </Button>
        </div>
      </div>

      <div className='hidden'>
        {
          record &&
            <div ref={ticketRef} className='py-6 px-4' style={{ width: '300px', maxWidth: '300px', fontSize: '12px', height: 'max-content' }}>
              <div className='text-center'>
                <h1 className='mb-2 text-xl'><b>La casa del Pastelero</b></h1>
                <p>Av. Ruta Hidalgo, 9698, El Florido 1ra. Sección Tijuana, 22237, B.C., México</p>
              </div>

              <Table className='mt-12'>
                <TableHead>
                  <TableRow>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Producto</TableCell>
                    <TableCell>Precio</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(record.order_items || []).map(orderItem => {
                    return (
                      <TableRow key={orderItem.id}>
                        <TableCell className='align-middle'>{orderItem.amount}</TableCell>
                        <TableCell className='align-middle'>
                          <p>{orderItem.product_name}</p>
                          <p>{orderItem.sales_presentation_name}</p>
                        </TableCell>
                        <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.unit_price)}</TableCell>
                        <TableCell className='text-right is-narrow align-middle'>{toCurrency(orderItem.total)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>

                <TableFoot>
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell className='text-right'>{toCurrency(record.total)}</TableCell>
                  </TableRow>
                </TableFoot>
              </Table>

              <div className='text-center'>
                <p className='mt-6 text-md'><b>¡Gracias por tu preferencia!</b></p>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default AdminCashierOrderCompleted
