import { buildApiUrl, toPercentage, toCurrency, classNames } from '../../utils'
import { useFetch } from '../../hooks'
import { LoadingIcon } from '../../icons'

const Metric = ({ title = '', path, queryParams = {}, format = 'number' }) => {
  const url = buildApiUrl(path, queryParams)
  const { data } = useFetch(url)
  const metric = data?.data

  const formatValue = (value) => {
    if (format === 'currency') {
      return toCurrency(value)
    } else if (format === 'percentage') {
      return toPercentage(value)
    } else {
      return value
    }
  }

  return (
    <div className='shadow-smooth flex flex-col rounded-3xl bg-white px-6 py-6 flex-1'>
      <div className='flex flex-1 flex-col justify-center items-center py-16'>
        {
          !metric
            ? <LoadingIcon />
            : <>
              <p className='text-6xl'>{formatValue(metric.value || 0)}</p>
              <p
                className={classNames('text-base', { 'text-red-500': metric.diff < 0, 'text-green-500': metric.diff > 0 })}
              >
                {toPercentage(metric.diff)}
              </p>
            </>
        }
      </div>
      <p className='text-2xl text-center text-gray-700'>{title}</p>
    </div>
  )
}

export default Metric
