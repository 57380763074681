import { Modal } from '../../components'
import { Outlet, useLocation } from 'react-router-dom'

const ModalLayout = () => {
  const { state } = useLocation()

  return (
    <Modal isActive size={state.modalSize || ''} state={state.modalHeight || ''}>
      <Outlet />
    </Modal>
  )
}

export default ModalLayout
