export const CircleXMarkIcon = ({ color = 'currentColor', className = 'icon' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className={className}>
      <path
        fill={color} d='M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm-81-337c9-9 25-9 34
        0l47 47 47-47c9-9 24-9 34 0s9 25 0 34l-47 47 47 47c9 9 9 24 0 34s-25 9-34 0l-47-47-47
        47c-10 9-25 9-34 0s-9-25 0-34l47-47-47-47c-9-10-9-25 0-34z'
      />
    </svg>
  )
}
