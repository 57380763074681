import { useContext, useState } from 'react'
import { Header, Form, TextField, SelectField, TertiaryLink, Button } from '../../../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../../../../hooks'
import { translations } from '../../../../../locales'
import { adminRoute } from '../../../../../routesHelpers'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { buildApiUrl } from '../../../../../utils'

const AdminEditOrderItem = () => {
  const { orderToken, orderItemId } = useParams()
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items/${orderItemId}`, { locale })
  const { data } = useFetch(url)
  const orderItem = data.data

  const onSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'), { state: { order: data } })
  }

  return (
    <>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.orderItem.toLowerCase()}`} />

      {orderItem &&
        <Form
          method='PUT'
          url={url}
          onSuccessfulSubmit={onSuccessfulSubmit}
          onFailedSubmit={setErrors}
        >
          <TextField type='hidden' method='locale' defaultValue={locale} serverError={errors.amount} min={0} />

          <div className='flex justify-between gap-x-2'>
            <TextField type='number' method='octopus_os_shop_order_item.amount' defaultValue={orderItem.amount} step='0.001' />

            <SelectField
              method='octopus_os_shop_order_item.octopus_os_product_sales_presentation_id'
              fieldClass='flex-1 mb-4'
              defaultValue={{ label: `${orderItem.product_name} (${orderItem.sales_presentation_name})`, value: orderItem.sales_presentation_id }}
              disabled
            />
          </div>

          <div className='flex justify-end gap-x-2'>
            <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>

            <Button type='submit'>{translations[locale].helpers.save.text}</Button>
          </div>
        </Form>}
    </>
  )
}

export default AdminEditOrderItem
