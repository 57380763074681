import { Header, TertiaryLink } from '../../../../../components'
import { EditIcon } from '../../../../../icons'
import { adminRoute } from '../../../../../routesHelpers'

const AdminCashierCustomerInfo = ({ order, editable = true }) => {
  if (order && Object.keys(order).length < 0) {
    return (<p>Loading...</p>)
  } else {
    return (
      <>
        {editable &&
          <Header title={order.customer_name}>
            <TertiaryLink href={adminRoute('editCashierCustomerInfo', { orderToken: order.token })} modal>
              <EditIcon />
            </TertiaryLink>
          </Header>}

        <p className='text-sm'>{order.customer?.legal_name} &nbsp;</p>
        <p className='text-sm'>{order.customer?.rfc} &nbsp;</p>
        <p className='text-sm'>{order.customer?.email} &nbsp;</p>
        <p className='text-sm'>{order.customer?.phone} &nbsp;</p>
      </>
    )
  }
}
export default AdminCashierCustomerInfo
