import { useLocation } from 'react-router-dom'

export const useReferrer = () => {
  const location = useLocation()

  const previousLocation = location.state?.previousLocation
  if (!previousLocation) return

  return `${previousLocation.pathname}${previousLocation.search}`
}
