import { useContext } from 'react'
import {
  Header, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination,
  Dropdown, DropdownItem, Tag
} from '../../../components'
import { useFetch } from '../../../hooks/useFetch'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { buildApiUrl, toCurrency } from '../../../utils'
import { useSearchParams } from 'react-router-dom'
import { translations } from '../../../locales'
import { adminRoute } from '../../../routesHelpers'

const AdminInvoices = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const [searchParams] = useSearchParams()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/invoices`, { ...Object.fromEntries(searchParams), locale })
  const { data } = useFetch(url, [], [updatedAt])

  return (
    <>
      <Header title={translations[locale].invoices.title} />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Cliente</b></TableCell>
              <TableCell><b>RFC</b></TableCell>
              <TableCell><b>UUID</b></TableCell>
              <TableCell><b>Series</b></TableCell>
              <TableCell><b>Folio</b></TableCell>
              <TableCell><b>Total</b></TableCell>
              <TableCell><b>Estado</b></TableCell>
              <TableCell><b>Fecha de creación</b></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {data.data.map(record => {
              return (
                <TableRow key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'>
                  <TableCell>{record.customer_name}</TableCell>
                  <TableCell>{record.rfc}</TableCell>
                  <TableCell>{record.uuid}</TableCell>
                  <TableCell>{record.series}</TableCell>
                  <TableCell>{record.folio}</TableCell>
                  <TableCell>{toCurrency(record.total)}</TableCell>
                  <TableCell>
                    <Tag
                      text={record.status}
                      textColor={record.status === 'active' ? 'text-green-600' : 'text-red-600'}
                      backgroundColor={record.status === 'active' ? 'bg-green-100' : 'bg-red-100'}
                    />
                  </TableCell>
                  <TableCell>{record.created_at}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownItem
                        name={`${translations[locale].helpers.cancel.text}`}
                        textColor='text-red-500'
                        disabled={record.status === 'canceled' || record.is_global}
                        href={adminRoute('invoiceCancelation', { invoiceId: record.id })}
                        modal
                      />

                    </Dropdown>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-end'>
        {data.pagination &&
          <Pagination
            items={data.pagination.items} count={data.pagination.count} to={data.pagination.to}
            from={data.pagination.from} prev={data.pagination.prev} next={data.pagination.next}
          />}
      </div>
    </>
  )
}

export default AdminInvoices
