export const DashboardIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='icon'>
      <path
        fill={color} d='M0 256a256 256 0 1 1 512 0 256 256 0 1 1-512 0zm320 96c0-27-16-50-40-59V88a24
        24 0 1 0-48 0v205a64 64 0 1 0 88 59zM144 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm-16 80a32 32 0 1
        0-64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm-16-144a32 32 0 1 0-64 0 32 32 0 1 0 64 0z'
      />
    </svg>
  )
}
