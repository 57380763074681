import { NavLink } from 'react-router-dom'

const SidebarItem = ({ path, text, icon = null }) => {
  return (
    <NavLink className='text-white' to={path}>
      {
        !icon
          ? <p className='hidden md:block'>{text}</p>
          : <div className='flex gap-2 items-center'>
            {icon}
            <p className='hidden lg:block'>{text}</p>
          </div>
      }
    </NavLink>
  )
}

export default SidebarItem
