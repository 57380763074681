import { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LocaleContext, TenantTokenContext, TokenContext } from '../../../contexts'
import { Button } from '../../../components'
import { TrashIcon } from '../../../icons'
import { adminRoute, apiBaseUrl } from '../../../routesHelpers'
import { translations } from '../../../locales'
import AdminProductGeneralInformation from './Product/GeneralInformation/GeneralInformation'
import AdminProductEquivalence from './Product/Equivalence/ProductEquivalence'
import AdminProductSalesPresentations from './Product/SalesPresentations/SalesPresentations'

const AdminProduct = () => {
  const navigate = useNavigate()
  const { productId } = useParams()
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const { state } = useLocation()

  const destroyProduct = async () => {
    if (!window.confirm('¿Desea eliminar este producto?')) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      `${apiBaseUrl}/octopus_os/t/${tenantToken}/admin/products/${productId}`,
      { method: 'DELETE', headers }
    )

    if (response.ok) {
      navigate(adminRoute('products'), { state: { adminProducts: { updatedAt: Date.now() } } })
    }
  }

  return (
    <>
      <div className='mb-24'>
        <AdminProductGeneralInformation updatedAt={state?.productGeneralInformation?.updatedAt} />
      </div>

      <div className='mb-24'>
        <AdminProductEquivalence updatedAt={state?.productEquivalence?.updatedAt} />
      </div>

      <AdminProductSalesPresentations updatedAt={state?.productSalesPresentations?.updatedAt} />

      <hr className='w-full text-slate-400 mt-12 mb-6' />

      <Button className='bg-red-600 text-white' onClick={destroyProduct}>
        <TrashIcon color='currentColor' />
        <p className='ml-3'>{translations[locale].helpers.delete.text}</p>
      </Button>
    </>
  )
}

export default AdminProduct
