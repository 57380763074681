export const ArrowDownToLineIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' className='icon'>
      <path
        fill={color} d='M32 480a32 32 0 1 1 0-64h320a32 32 0 1 1 0 64H32zm183-137a32 32 0 0
      1-46 0L41 215a32 32 0 0 1 46-46l73 74V64a32 32 0 1 1 64 0v179l73-74a32 32 0 0 1 46 46L215 343z'
      />
    </svg>
  )
}
