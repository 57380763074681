import ProductForm from './ProductForm'
import { translations } from '../../../locales'
import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { Header } from '../../../components'
import { adminRoute } from '../../../routesHelpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { buildApiUrl } from '../../../utils'

const AdminEditProduct = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { id: productId } = useParams()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}`, { locale })
  const { data } = useFetch(url, null)

  const handleOnSuccess = () => {
    navigate(
      adminRoute('showProduct', { productId }),
      { state: { productGeneralInformation: { updatedAt: Date.now() }, productSalesPresentations: { updatedAt: Date.now() } } }
    )
  }

  return (
    <div>
      <Header title={`${translations[locale].helpers.edit.text} ${translations[locale].models.product.toLowerCase()}`} />

      {
        data.data &&
          <ProductForm
            url={url}
            method='PUT'
            onSuccess={handleOnSuccess}
            product={data.data}
          />
      }
    </div>
  )
}

export default AdminEditProduct
