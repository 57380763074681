const routes = {
  root: '/'
}

const adminRoutes = {
  dashboard: '/admin/dashboard',
  products: '/admin/products',
  orders: '/admin/orders',
  showOrder: '/admin/orders/:orderToken',
  editOrderCustomer: '/admin/orders/:orderToken/customer/edit',
  cashier: '/admin/cashier',
  newProduct: '/admin/products/new',
  showProduct: '/admin/products/:productId',
  editProduct: '/admin/products/edit/:id',
  newSalesPresentation: '/admin/products/:productId/sales-presentations/new',
  editSalesPresentation: '/admin/products/:productId/sales-presentations/:salesPresentationId/edit',
  productEquivalences: '/admin/products/:productId/product-equivalences',
  salesPresentation: '/admin/products/:productId/sales-presentations/:salesPresentationId',
  editProductEquivalence: '/admin/products/:productId/product/equivalences/edit',
  customers: '/admin/customers',
  newCustomer: '/admin/customers/new',
  editCustomer: '/admin/customers/edit/:id',
  editCashierCustomerInfo: '/admin/cashier/:orderToken/customer-info/edit',
  newCashierOrderItem: '/admin/cashier/:orderToken/items/new',
  editCashierOrderItem: '/admin/cashier/:orderToken/items/:orderItemId/edit',
  editDiscountCashierOrderItem: '/admin/cashier/:orderToken/items/:orderItemId/discount/edit',
  cashierOrderPayment: '/admin/cashier/:orderToken/payment',
  cashierOrderCompleted: '/admin/cashier/:orderToken/completed',
  cashierOrders: '/admin/cashier/orders',
  invoices: '/admin/invoices',
  invoiceCancelation: '/admin/invoices/:invoiceId/cancel',
  cashClosings: '/admin/cash-closings',
  newCashClosing: '/admin/cash-closings/new',
  cashClosing: '/admin/cash-closings/:id',
  metrics: '/admin/metrics',
  vendors: '/admin/vendors',
  newVendor: '/admin/vendor/new',
  editVendor: '/admin/vendor/:id/edit'
}

export const route = (routeName) => {
  return routes[routeName]
}

export const adminRoute = (routeName, params = {}) => {
  let url = adminRoutes[routeName]
  for (const [param, value] of Object.entries(params)) {
    url = url.replace(`:${param}`, value)
  }
  return url
}

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
