export const TruckFieldIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='icon'>
      <path
        fill={color} d='M32 96c0-35 29-64 64-64h224c24 0 44 13 55 32h52c25 0 48 15 59 38l52 119
        2 3h4c35 0 64 29 64 64v32a32 32 0 1 1 0 64h-32a96 96 0 0 1-192 0H256a96 96 0 0 1-192 0H32a32
        32 0 1 1 0-64v-32c-18 0-32-14-32-32v-96c0-18 14-32 32-32V96zm352 128h86l-43-96h-43v96zM160
        432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0-96 0 48 48 0 1 0 96 0z'
      />
    </svg>
  )
}
