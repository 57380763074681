import { useContext, useState } from 'react'
import { Header, Form, TextField, TertiaryLink, Button } from '../../../../../../components'
import { translations } from '../../../../../../locales'
import { LocaleContext, TenantTokenContext } from '../../../../../../contexts'
import { buildApiUrl } from '../../../../../../utils'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRoute } from '../../../../../../routesHelpers'
import { useFetch } from '../../../../../../hooks'

const AdminEditDiscountCashierOrderItem = () => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { orderToken, orderItemId } = useParams()
  const [, setErrors] = useState()
  const navigate = useNavigate()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items/${orderItemId}/discounts`, { locale })

  const orderItemUrl = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}/items/${orderItemId}`, { locale })
  const { data } = useFetch(orderItemUrl)
  const orderItem = data.data

  const onSuccessfulSubmit = (data) => {
    navigate(adminRoute('cashier'), { state: { order: data }, replace: true })
  }

  return (
    <>
      <Header title={translations[locale].cashier.orderItems.discount.edit.title} />

      {
        orderItem &&
          <Form
            method='PUT'
            url={url}
            onSuccessfulSubmit={onSuccessfulSubmit}
            onFailedSubmit={(errors) => { setErrors(errors) }}
          >
            <TextField type='hidden' method='type' defaultValue='percentage' />

            <div className='flex items-center mb-5'>
              <TextField
                method='unit_discount'
                type='number' step={0.1} min={0} max={100}
                fieldClass='w-full'
                validates={{ required: 'No puede estar en blanco' }}
                validate={{ inclusion: (v) => (parseFloat(v) > -1 && parseFloat(v) <= 100) || 'Debe ser mayor que 0 y menor 100' }}
                defaultValue={(parseFloat(orderItem.unit_discount) * 100) / parseFloat(orderItem.unit_price)}
              />

              <p className='ml-4'>%</p>
            </div>

            <div className='flex justify-end gap-x-2'>
              <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
              <Button type='submit'>{translations[locale].helpers.save.text}</Button>
            </div>
          </Form>
      }

    </>
  )
}

export default AdminEditDiscountCashierOrderItem
