import { useContext, useState } from 'react'
import { Form, SelectField, TextAreaField, TextField, TertiaryLink, Button } from '../../../components'
import { LocaleContext } from '../../../contexts'
import { translations } from '../../../locales'
import { buildApiUrl } from '../../../utils'

const AdminCustomerForm = ({ url, method, customer = {}, onSuccess = () => {} }) => {
  const { locale } = useContext(LocaleContext)
  const [errors, setErrors] = useState({})

  return (
    <Form
      method={method}
      url={url}
      onSuccessfulSubmit={(data) => onSuccess(data)}
      onFailedSubmit={(errors) => setErrors(errors)}
    >
      <TextField type='hidden' method='locale' defaultValue={locale} />

      <TextField
        method='octopus_os_shop_customer.name'
        placeholder={translations[locale].attributes.customer.firstName}
        label={translations[locale].attributes.customer.firstName}
        fieldClass='mb-4' serverError={errors.firstName}
        defaultValue={customer?.name}
      />

      <TextField
        method='octopus_os_shop_customer.legal_name'
        placeholder={translations[locale].attributes.customer.legalName}
        label={translations[locale].attributes.customer.legalName}
        fieldClass='mb-4 flex-1' serverError={errors.legalName}
        defaultValue={customer?.legal_name}
      />

      <div className='flex justify-between gap-x-2'>
        <SelectField
          method='octopus_os_shop_customer.tax_regime'
          placeholder={translations[locale].attributes.customer.taxRegime}
          label={translations[locale].attributes.customer.taxRegime}
          fieldClass='mb-4 flex-1' serverError={errors.taxRegime}
          defaultValue={customer?.tax_regime_for_select}
          fetchOptionsUrl={buildApiUrl('/octopus_os/tax_regimes')}
        />

        <SelectField
          method='octopus_os_shop_customer.cfdi_use'
          placeholder={translations[locale].attributes.customer.cfdiUse}
          label={translations[locale].attributes.customer.cfdiUse}
          fieldClass='mb-4 flex-1' serverError={errors.cfdiUse}
          defaultValue={customer?.cfdi_use_for_select}
          defaultOptions={[{ label: 'Adquisición de mercancías (G01)', value: 'G01' }, { label: 'Gastos en general (G03)', value: 'G03' }]}
        />
      </div>

      <TextField
        method='octopus_os_shop_customer.rfc'
        placeholder={translations[locale].attributes.customer.rfc}
        label={translations[locale].attributes.customer.rfc}
        fieldClass='mb-4' serverError={errors.rfc}
        defaultValue={customer?.rfc}
      />

      <TextField
        method='octopus_os_shop_customer.email'
        placeholder={translations[locale].attributes.customer.email}
        label={translations[locale].attributes.customer.email}
        fieldClass='mb-4' serverError={errors.email}
        defaultValue={customer?.email} type='email'
      />

      <TextField
        method='octopus_os_shop_customer.phone'
        placeholder={translations[locale].attributes.customer.phone}
        label={translations[locale].attributes.customer.phone}
        fieldClass='mb-4' serverError={errors.phone}
        defaultValue={customer?.phone} type='phone' min={10} max={13}
      />

      <TextField
        method='octopus_os_shop_customer.street'
        placeholder={translations[locale].attributes.customer.street}
        label={translations[locale].attributes.customer.street}
        fieldClass='mb-4' serverError={errors.street}
        defaultValue={customer?.street}
      />

      <TextField
        method='octopus_os_shop_customer.street_number'
        placeholder={translations[locale].attributes.customer.streetNumber}
        label={translations[locale].attributes.customer.streetNumber}
        fieldClass='mb-4' serverError={errors.streetNumber}
        defaultValue={customer?.street_number}
      />

      <TextAreaField
        method='octopus_os_shop_customer.additional_info'
        placeholder={translations[locale].attributes.customer.additionalInfo}
        label={translations[locale].attributes.customer.additionalInfo}
        fieldClass='mb-4' serverError={errors.additionalInfo}
        defaultValue={customer?.additional_info}
      />

      <TextField
        method='octopus_os_shop_customer.neighborhood'
        placeholder={translations[locale].attributes.customer.neighborhood}
        label={translations[locale].attributes.customer.neighborhood}
        fieldClass='mb-4' serverError={errors.neighborhood}
        defaultValue={customer?.neighborhood}
      />

      <TextField
        method='octopus_os_shop_customer.zip_code'
        placeholder={translations[locale].attributes.customer.zipCode}
        label={translations[locale].attributes.customer.zipCode}
        fieldClass='mb-4' serverError={errors.zipCode}
        defaultValue={customer?.zip_code} min={5} max={5}
      />

      <TextField
        method='octopus_os_shop_customer.city'
        placeholder={translations[locale].attributes.customer.city}
        label={translations[locale].attributes.customer.city}
        fieldClass='mb-4' serverError={errors.city}
        defaultValue={customer?.city}
      />

      <TextField
        method='octopus_os_shop_customer.state'
        placeholder={translations[locale].attributes.customer.state}
        label={translations[locale].attributes.customer.state}
        fieldClass='mb-4' serverError={errors.state}
        defaultValue={customer?.state}
      />

      <TextField
        method='octopus_os_shop_customer.country'
        placeholder={translations[locale].attributes.customer.country}
        label={translations[locale].attributes.customer.country}
        fieldClass='mb-4' serverError={errors.country}
        defaultValue='México' disabled
      />

      <div className='flex justify-end gap-x-2'>
        <TertiaryLink href={-1} className='text-gray-500 font-bold'>{translations[locale].helpers.cancel.text}</TertiaryLink>
        <Button type='submit'>{translations[locale].helpers.save.text}</Button>
      </div>
    </Form>
  )
}

export default AdminCustomerForm
