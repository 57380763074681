export const CashClosingIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' className='icon'>
      <path
        fill={color} d='M64 96v64a32 32 0 1 1-64 0V96C0 43 43 0 96 0h448c53 0 96 43 96 96v64a32
        32 0 1 1-64 0V96c0-18-14-32-32-32H96c-18 0-32 14-32 32zm64 0h384v352c0 35-29 64-64 64H192c-35
        0-64-29-64-64V96zm192 256c53 0 96-36 96-80s-43-80-96-80-96 36-96 80 43 80 96 80z'
      />
    </svg>
  )
}
