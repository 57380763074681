export const ChartIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' className='icon'>
      <path
        fill={color} d='M160 80c0-26 22-48 48-48h32c27 0 48 22 48 48v352c0 27-21 48-48
        48h-32c-26 0-48-21-48-48V80z' style={{ opacity: 0.4 }}
      />
      <path
        fill={color} d='M368 96c-26 0-48 22-48 48v288c0 27 22 48 48 48h32c27 0 48-21 48-48V144c0-26-21-48-48-48h-32zM48
        224c-26 0-48 22-48 48v160c0 27 22 48 48 48h32c27 0 48-21 48-48V272c0-26-21-48-48-48H48z'
      />
    </svg>
  )
}
