const LabelValue = ({ label, value, children, ...props }) => {
  return (
    <div {...props}>
      <p><b>{label}</b></p>
      {
        children || <p>{value || <>&nbsp;</>}</p>
      }
    </div>
  )
}

export default LabelValue
