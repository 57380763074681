import { useFormContext } from 'react-hook-form'
import { methodToDomId } from '../../utils'
import { ErrorMessage } from '@hookform/error-message'
import { forwardRef } from 'react'

const TextField = forwardRef(({
  label, method, placeholder, defaultValue, serverError, disabled = false, fieldClass = '', labelClass = '', type = 'text',
  validates = {}, validate = {},
  ...props
}, parentRef) => {
  const id = methodToDomId(method)
  const { register, formState: { errors } } = useFormContext()
  const { onChange, onBlur, name, ref: inputRef, value } = register(method, { value: defaultValue, validate, ...validates })

  const combineRefs = (element) => {
    if (parentRef) { parentRef.current = element }

    inputRef(element)
  }

  return (
    <div className={`field ${fieldClass}`}>
      {label && <label htmlFor={id} className={`label ${labelClass}`}><b>{label}</b></label>}
      <input
        id={id}
        ref={combineRefs}
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        disabled={disabled}
        {...props}
      />
      <ErrorMessage
        errors={errors} name={name}
        render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>}
      />
      {(serverError) && <p className='text-red-500 text-sm'>{serverError}</p>}
    </div>
  )
})

export default TextField
