export const CupcakeIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' className='icon'>
      <path
        fill={color} d='M240 0a16 16 0 0 0-11 27l6 6a18 18 0 0 1-13 31h-62c-35 0-64 29-64
        64v1l-10 2-35 12a75 75 0 0 0 23 145h300a74 74 0 0 0 23-145l-35-12-13-3c2-6 3-13 3-21C352
        48 304 0 245 0h-5zm28 512 24-192H156l24 192h88zM64 320l25 152c4 23 24 40 48 40h11l-24-192H64zm236
        192h11c24 0 44-17 48-40l25-152h-60l-24 192z'
      />
    </svg>
  )
}
